<template lang="pug">
.test
    .text-h5 Edit Hierarchy Here

    v-overlay(absolute, :value='busy')
        .text-h6 Please Wait...
        v-progress-linear(indeterminate)

    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ message }}
    
</template>

<script>
import _ from 'lodash';

export default {
    data () {
        return {
            dti,
            accessKey: 'sathierarchy',
            message: '',
            busy: false,

        };
    },
    methods: {
        getTemplateData () {
            this.socketEmit('getTemplateData', null, (results) => {
                this.controllerData = results.controllers;
                this.panelData = results.panels;
            });
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            },
        },
    },
    watch: {

    },
    mounted () {

    }
};
</script>

<style lang="scss" scoped>


</style>

<style lang="scss">

</style>
